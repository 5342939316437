import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, DestroyRef, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime, Subject, Subscription, tap } from "rxjs";

@Component({
  template: ''
})
export abstract class PageWithListComponent<T> {
  private scrollSubscription: Subscription;
  /**
   * @param viewport - The scrolling viewport
   * Make sure that the <cdk-virtual-scroll-viewport> element has the 
   * #scrollViewport template reference variable
   */
  @ViewChild('scrollViewport')
  set scrollViewport(viewport: CdkVirtualScrollViewport) {
    if (!viewport) {
      this.scrollSubscription?.unsubscribe();
      return;
    }
    this.scrollSubscription = this.registerScrollView<T>(
      viewport,
      this.getScrollingList()
    ).pipe(
      takeUntilDestroyed(this.getDestroyRef())
    ).subscribe();
  }

  private onScrollSubject = new Subject<void>();

  private registerScrollView<T>(
    scrollElement: CdkVirtualScrollViewport,
    scrollingList: ScrollingList<T>
  ) {
    return scrollElement.scrolledIndexChange.pipe(
      debounceTime(100),
      tap(() => this.onScrollSubject.next()),
      tap(index => (
        index !== 0
        && scrollElement.getRenderedRange().end >= scrollElement.getDataLength() * 0.8
        && !scrollingList.loadingItems
      ) && scrollingList.load()
    ));
  }

  abstract getScrollingList(): ScrollingList<T>;
  abstract getDestroyRef(): DestroyRef;

  protected get onScroll() {
    return this.onScrollSubject.asObservable();
  }
}


export interface ScrollingList<T> {
  load: () => void;
  doSearch: () => void;
  sortBy: (sortBy: string, reverse?: boolean) => void;
  select: (item: T) => void;
  selectAll: () => void;
  loadingItems: boolean;
  apiError: string;
  errorMessage: string;
  items: {
    list: T[];
  }
}
